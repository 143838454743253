import Button, { ButtonProps } from '@lyra/core/components/Button'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import useAuth from '@lyra/web/hooks/useAuth'
import RainbowKitProvider from '@lyra/web/providers/RainbowKitProvider'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useEffect } from 'react'
import { useAccount, useDisconnect } from 'wagmi'

type ReconnectWalletButtonProps = Omit<ButtonProps, 'onPress' | 'label'> & {
  onChangeOpenModal?: (isOpen: boolean) => void
}

const NestedButton = ({
  onChangeOpenModal,
  isDisabled,
  ...buttonProps
}: ReconnectWalletButtonProps) => {
  const { isAuthenticated, user } = useAuth()
  const { address } = useAccount()
  const { disconnectAsync } = useDisconnect()
  const { openConnectModal, connectModalOpen } = useConnectModal()

  useEffect(() => {
    onChangeOpenModal?.(connectModalOpen)
  }, [connectModalOpen, onChangeOpenModal])

  return (
    <Button
      {...buttonProps}
      color="cta"
      // show solid CTA if user is authenticated and not connected
      isSolid={user && !address}
      isDisabled={!isAuthenticated || address === user.ownerAddress || isDisabled}
      label={
        !user
          ? 'RECONNECT WALLET'
          : address && address !== user.ownerAddress
          ? `DISCONNECT WALLET (${formatTruncatedAddress(address)})`
          : `RECONNECT WALLET (${formatTruncatedAddress(user.ownerAddress)})`
      }
      textTransform="none"
      onPress={async () => {
        if (address) {
          await disconnectAsync()
        } else if (openConnectModal) {
          openConnectModal()
        }
      }}
    />
  )
}

export default function ReconnectWalletButton(props: ReconnectWalletButtonProps) {
  return (
    <RainbowKitProvider>
      <NestedButton {...props} />
    </RainbowKitProvider>
  )
}
