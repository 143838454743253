import Button, { ButtonProps } from '@lyra/core/components/Button'
import { Check, Copy } from '@lyra/core/components/Icon'
import { useState } from 'react'

type Props = {
  value: string
} & ButtonProps

export default function CopyButton({ value, icon, ...buttonProps }: Props) {
  const [isValueCopied, setIsValueCopied] = useState(false)
  const copyValueToClipboard = () => {
    setIsValueCopied(true)
    navigator.clipboard.writeText(value)
    setTimeout(() => setIsValueCopied(false), 1000)
  }
  return (
    <Button
      {...buttonProps}
      onPress={(e) => {
        e.stopPropagation()
        copyValueToClipboard()
      }}
      icon={isValueCopied ? <Check /> : icon ? icon : <Copy />}
    />
  )
}
